import L from 'leaflet'

const DEFAULT_THEME = 'theme-white'

let isMobile = undefined
let currentTheme = window.localStorage.getItem('theme')

const themeBtn = document.getElementById('theme-btn')

if (currentTheme === null) {
    if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            currentTheme = 'dark'
        } else {
            currentTheme = 'white'
        }
        window.localStorage.setItem('theme', currentTheme)
        isMobile = window.matchMedia("max-width: 480px").matches;
    } else {
        currentTheme = DEFAULT_THEME
    }
}

if (currentTheme === 'white') {
    themeBtn.innerText = 'dark_mode'
} else {
    themeBtn.innerText = 'light_mode'
}

document.body.className = `theme-${currentTheme}`

themeBtn.addEventListener('click', () => {
    if (currentTheme === 'white') {
        themeBtn.innerText = 'light_mode'
        currentTheme = 'dark'
    } else {
        themeBtn.innerText = 'dark_mode'
        currentTheme = 'white'
    }
    document.body.className = `theme-${currentTheme}`
    window.localStorage.setItem('theme', currentTheme)
})

document.getElementById('menu-btn').addEventListener('click', () => {
    document.getElementById('top-nav').className = 'open'
})

document.getElementById('top-nav').addEventListener('click', (event) => {
    event.stopPropagation();
})

document.body.addEventListener('click', () => {
    document.getElementById('top-nav').className = ''
})

const searchQ = document.getElementById('q')

if (searchQ) {
    if (isMobile) {
        searchQ.onfocus = (event) => {
            event.target.scrollIntoView(true)
        }
    }

    let searchAbortController;

    const searchbox = document.getElementById('searchbox')
    const searchResult = document.getElementById('search-result')

    const showSearchResults = (visible) => {
        searchbox.className = visible ? 'with-results' : 'no-results';
    }

    const search = async (q) => {
        if (searchAbortController) {
            searchAbortController.abort()
        }

        searchAbortController = new AbortController()

        try {
            const response = await fetch(`/v1/search?q=${encodeURIComponent(q)}`, {
                method: 'GET',
                signal: searchAbortController.signal,
            })

            if (!response.ok) {
                throw new Error(`Search request error: ${response.status}`)
            }

            const data = await response.json()
            
            showSearchResults(data.hits.length > 0)

            const itemsHtml = []
            for (const hit of data.hits) {
                itemsHtml.push(
                    `<a href="${hitUrl(hit)}" class="search-item" onclick="event.stopPropagation()">
            <p><strong>${formatCnpj(hit.cnpj)}</strong><p>
            <p class="nome-fantasia">${hit.nome_fantasia || hit.razao_social}</p>
            <p class="municipio-uf">${hit.municipio} - ${hit.uf}</p>
    </div>`
                )
            }

            searchResult.innerHTML = itemsHtml.join('')
        } catch (err) {
            console.error(err)
        }
    }

    searchQ.addEventListener('keyup', async (event) => {
        const q = searchQ.value.trim()
        if (q.length < 3) {
            showSearchResults(false)
            return
        }

        search(q)
    })

    window.addEventListener('click', () => {
        showSearchResults(false)
    })
}

const formatCnpj = (cnpj) => {
    return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12, 14)}`
}

const slugify = (text) => text.toString()
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')

const hitUrl = (hit) => {
    return `/empresas/${hit.uf.toLowerCase()}/${slugify(hit.municipio)}/${hit.cnpj}/${slugify(hit.razao_social)}`
}

const initMap = async () => {
    const addr = window.COMPANY.endereco;

    const params = new URLSearchParams({
        street: `${addr.tipo_logradouro} ${addr.logradouro}, ${addr.numero}`,
        city: addr.municipio,
        state: addr.uf,
        potalcode: addr.cep,
        // country: window.COMPANY.pais ? window.COMPANY.pais : 'Brasil',
        format: 'geojson',
        polygon_geojson: 1,
        "accept-language": 'pt-BR,pt'
    })

    const response = await fetch(`https://nominatim.openstreetmap.org/search?${params.toString()}`)
    const data = await response.json()
    if (!data) {
        return
    }

    if (data.features.length === 0) {
        return
    }

    const map = L.map('map').setView([-12.660341,-59.6897367], 4);
    map.attributionControl.setPrefix(false);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    const layer = L.geoJSON(data).addTo(map)

    map.flyToBounds(layer.getBounds(), {
        animate: false,
    })
}

if (window.COMPANY) {
    initMap()
}